import { React, Component, useState } from 'react';
import { auth, db } from "./index"
import { collection, getDoc, query, where, getDocs } from 'firebase/firestore/lite';
import { AdminHome } from './admin/adminHome';
import { BusinessHome } from './business/businessHome';
import { PickPrivilege } from './pickPrivilege';
import { ChainHome } from './chain/chainHome';
import { AssistantHome } from './assistant/home';

export function Home(props) {
    const [doc, changeDoc] = useState(null);
    const [ready, changeReady] = useState(false);
    async function getUserAndDoc() {
        if (!ready) {
            let user = await auth.currentUser;
            console.log(user.uid);
            const q = await getDocs(query(collection(db, "admins"), where("UID", "==", user.uid)));
            if (q.docs.length != 0) {
                changeDoc(q.docs[0]);
                changeReady(true);
            } else {
                props.logout();
            }
        }
    }
    getUserAndDoc();
    if (ready) {
        let data = doc.data();
        console.log(data);
        return <div>
            {data["privilege"] != "assistant" ? <div className="h-[50px] bg-gray-500 w-[100%] flex flex-row pl-[20px] justify-between items-center pr-[20px]">
                <div className="text-white text-xl font-bold">Tayau.kz (Админская панель)</div>
                <div className="text-white">{data['email']}</div>
                <button onClick={() => {
                    props.logout();
                }} className="h-[30px] w-[60px] rounded-md flex items-center justify-center bg-red-300 text-white">Выйти</button>
            </div> : <></>}
            {data["privilege"] == "admin" ? <AdminHome></AdminHome> :
                data["privilege"] == "assistant" ? <AssistantHome userDoc={doc}></AssistantHome> :
                    data["privilege"] == "business" ? <BusinessHome storeID={data['storeID']}></BusinessHome> :
                        data["privilege"] == "chain" ? <ChainHome></ChainHome> :
                            <PickPrivilege></PickPrivilege>}
        </div>

    } else {
        return <></>;
    }
}

