import { React, useState } from "react";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../index";

let images = [];

export function FirebaseImage(props) {
    const [ready, changeReady] = useState(false);
    if (props.reference != null) {
        let fit = "object-cover" + " rounded-md";
        if (props.fit != null) {
            fit = props.fit + "rounded-md";
        }
        async function getReady() {
            if (!ready) {
                if (images.findIndex((e) => e['ref'] == props.reference) == -1) {
                    let url = await getDownloadURL(ref(storage, props.reference));
                    images.push({ "ref": props.reference, "url": url });
                }
                changeReady(true);
            }
        }
        getReady();
        if (ready) {
            let ind = images.findIndex((e) => e['ref'] == props.reference);
            if (ind != -1) {
                return <img className={fit} src={images[ind]['url']}></img>;
            } else {
                return <></>;
            }
        } else {
            return <></>;
        }
    } else {
        return <></>;
    }
}