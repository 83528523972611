import {React, useState} from "react"
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore, updateDoc, setDoc, doc, collection, addDoc, Timestamp, getDoc, getDocs, where, query} from "firebase/firestore/lite";
import { app, auth, db } from "../index";

export function Auth(props) {
    const [state, changeState] = useState("login");
    return <div className="h-[100%] bg-gray-100 flex flex-col items-center p-[20px]">
        <div className="text-3xl font-bold">Tayau.kz</div>
        <div className="h-[20px]"></div>
        {state == "login" ? <Login user={(val) => { props.user(val); }} forgot={() => {
            changeState('forgot');
        }} register={() => {
            changeState("reg");
        }}></Login> : state == "reg" ? <Reg user={(val) => { props.user(val); }} login={() => {
            changeState("login");
        }}></Reg> : <Forgot login={() => { changeState("login") }}></Forgot>}</div>;
}

function Login(props) {
    const [email, enterEmail] = useState("");
    const [password, enterPassword] = useState("");
    const [logginIn, changeLogin] = useState(false);
    const [error, changeError] = useState("");

    async function loginFunc() {
        if (email != "" && password != "") {
            changeLogin(true);
            changeError("");
            await signInWithEmailAndPassword(auth, email, password).then(async(done) => {
                console.log(`Logged in, uid: ${done.user.uid}`);
                props.user(done.user);
            }).catch((err) => {
                changeError(err.toString());
            });
            changeLogin(false);
        }
    }

    return <>
        <div className="w-[400px] h-[400px] p-[20px] bg-gray-200 rounded-md">
            <div className="text-xl font-bold">Вход</div>
            <div className="h-[20px]"></div>
            <input onChange={(val) => {
                enterEmail(val.currentTarget.value);
            }} placeholder="Email" className="w-[100%] h-[50px] pl-[20px] rounded-md bg-white"></input>
            <div className="h-[20px]"></div>
            <input onChange={(val) => {
                enterPassword(val.currentTarget.value);
            }} placeholder="Пароль" className="w-[100%] h-[50px] pl-[20px] rounded-md bg-white"></input>
            <div className="h-[20px]"></div>
            {!logginIn ? <div onClick={() => { loginFunc(); }} className="h-[50px] w-[100%] flex items-center justify-center rounded-md bg-blue-600 text-white font-bold">Войти</div>
                : <div className="h-[50px] w-[100%] flex items-center justify-center text-blue-600">Загрузка</div>}
            {error != "" ? <div>
                <div className="h-[20px]"></div>
                <div>{error}</div>
            </div> : <></>}
            <div className="h-[20px]"></div>
            <div onClick={() => { props.forgot(); }}>Забыли пароль?</div>
            <div className="h-[20px]"></div>
            <div onClick={() => { props.register(); }}>Зарегистрироваться</div>
        </div>
    </>;
}

function Reg(props) {
    const [email, enterEmail] = useState("");
    const [password, enterPassword] = useState("");
    const [registering, changeRegistering] = useState(false);
    const [error, changeError] = useState("");
    async function register() {
        if (email != "" && password != "") {
            changeRegistering(true);
            await createUserWithEmailAndPassword(auth, email, password).then(async(done) => {
                console.log(`done ${done.user.uid}`);
                if(done.user.uid!=null){
                    await addDoc(collection(db, "admins"), {
                        "createdAt": Timestamp.now(),
                        "privilege": "",
                        "UID": done.user.uid, 
                        "email": email, 
                    });
                }
                props.user(done.user);
            }).catch((err) => {
                changeError(err.toString());
            });
            changeRegistering(false);
        }
    }

    return <>
        <div className="w-[400px] h-[400px] p-[20px] bg-gray-200 rounded-md">
            <div className="text-xl font-bold">Регистрация</div>
            <div className="h-[20px]"></div>
            <input onChange={(val) => {
                enterEmail(val.currentTarget.value);
            }} placeholder="Email" className="w-[100%] h-[50px] pl-[20px] rounded-md bg-white"></input>
            <div className="h-[20px]"></div>
            <input  onChange={(val) => {
                enterPassword(val.currentTarget.value);
            }} placeholder="Пароль" className="w-[100%] h-[50px] pl-[20px] rounded-md bg-white"></input>
            <div className="h-[20px]"></div>
            {!registering ? <div onClick={() => { register(); }} className="h-[50px] w-[100%] flex items-center justify-center rounded-md bg-green-600 text-white font-bold">Регистрация</div>
                : <div className="h-[50px] w-[100%] flex items-center justify-center text-green-600">Загрузка</div>}
            {error != "" ? <div>
                <div className="h-[20px]"></div>
                <div>{error}</div>
            </div> : <></>}
            <div className="h-[20px]"></div>
            <div className="text-blue-600" onClick={() => { props.login(); }}>Уже есть аккаунт? Войти</div>
        </div>
    </>;
}

function Forgot(props) {
    const [email, enterEmail] = useState("");
    const [sent, changeSent] = useState(false);
    const [error, changeError] = useState("");
    return <>
        <div className="w-[400px] h-[400px] p-[20px] bg-gray-200 rounded-md">
            <div className="text-xl font-bold">Забыли пароль?</div>
            <div className="h-[20px]"></div>
            <input onChange={(val) => {
                // enterEmail(val.currentTarget.value);
            }} placeholder="Email" className="w-[100%] h-[50px] pl-[20px] rounded-md bg-white"></input>
            <div className="h-[20px]"></div>
            {!sent ? <div onClick={() => { }} className="h-[50px] w-[100%] flex items-center justify-center rounded-md bg-red-600 text-white font-bold">Отправить сброс</div>
                : <div className="h-[50px] w-[100%] flex items-center justify-center text-green-600">Загрузка</div>}
            {error != "" ? <div>
                <div className="h-[20px]"></div>
                <div>{error}</div>
            </div> : <></>}
            <div className="h-[20px]"></div>
            <div className="text-blue-600" onClick={() => { props.login(); }}>Войти в аккаунт</div>
        </div>
    </>;
}
