import { React, Component, useState } from 'react';
import { collection, getDoc, query, where, getDocs, getCount } from 'firebase/firestore/lite';
import { auth, db } from "../index"

export function AssistantLanding(props) {
    let [newStores, changeStores] = useState(0);
    let [addedStores, changeAddedStores] = useState("");
    let [loaded, changeLoaded] = useState(false);
    async function getInfo() {
        if (!loaded) {
            props.changeHomeLoading(true);
            const q = await getCount(query(collection(db, "newStores",), where("reviewStatus", ">=", 1), where("reviewStatus", "<=", 3)));
            let t = new Date();
            let weekStart = new Date(t.getYear(), t.getMonth(), t.getDate() - t.getDay(), 0, 0, 1);
            const w = await getCount(query(collection(db, "newStores"), where("reviewedDate", ">=", weekStart)));
            changeLoaded(true);
            changeStores(q.data().count);
            changeAddedStores(w.data().count);
            props.changeHomeLoading(false);
        }
    }
    let uData = props.user.data();
    getInfo();
    return <div className="flex flex-col p-[20px]">
        <div className="flex flex-wrap gap-[20px]">
            <InfoBox ft={newStores} st="Магазинов ожидают проверки"></InfoBox>
            <InfoBox ft={`${addedStores}/20`} st="Магазинов добавлено за неделю"></InfoBox>
            <InfoBox ft="0" st="Новых сообщений подержки"></InfoBox>
        </div>
        <div className="h-[20px]"></div>
        <ButtonBox onClick={() => { props.changePage("check"); }} text="Проверить магазины"></ButtonBox>
        <div className="h-[20px]"></div>
        <ButtonBox onClick={() => { props.changePage("newStore") }} text="Добавить новый магазин"></ButtonBox>
        <div className="h-[20px]"></div>
        <ButtonBox onClick={() => { props.changePage("messagePage") }} text="Ответить на сообщения"></ButtonBox>
        <div className="h-[20px]"></div>
        <div className="text-[16px] self-center font-bold text-black/[0.6]">{uData["name"]}</div>
        <div className="text-[16px] self-center text-black/[0.6]">Ассистент руководителя</div>
    </div>;
}


function InfoBox(props) {
    return <div className="w-[calc((100%-20px)/2)] h-[150px] justify-between bg-gray-200 rounded-md flex flex-col p-[10px]">
        <div className="font-bold text-[30px]">{props.ft}</div>
        <div className="text-[16px] text-end self-end">{props.st}</div>
    </div>;
}

function ButtonBox(props) {
    return <div onClick={() => { props.onClick(); }} className="w-[100%] h-[50px] items-center justify-between bg-gray-200 rounded-md pl-[20px] pr-[20px] flex flex-row">
        <div className="text-[16px]">{props.text}</div>
        <div>{">"}</div>
    </div>;
}