import { React, Component, useState } from "react"

export function RegistrationWid() {
    const [uploading, changeUpload] = useState(false);
    const [regMessage, updRegMessage] = useState("");
    const [data, changeData] = useState({
        'email': "",
        "pass": "",
    })


    function createAcc() {
        
    }

    return <div className="flex flex-col items-center">
        <div className="text-xl font-bold ">Регистрация</div>
        <div className="h-[20px]"></div>
        <input onChange={(val) => {
            let clone = structuredClone(data);
            clone["email"] = val.currentTarget.value;
            changeData(clone);
        }} className="h-[50px] w-[400px] bg-gray-300 rounded-md pl-[20px]" placeholder="Email"></input>
        <div className="h-[20px]"></div>
        <input onChange={(val) => {
            let clone = structuredClone(data);
            clone["pass"] = val.currentTarget.value;
            changeData(clone);
        }} className="h-[50px] w-[400px] bg-gray-300 rounded-md pl-[20px]" placeholder="Пароль"></input>
        <div className="h-[20px]"></div>
        <div onClick={() => { }} className="h-[50px] w-[400px] bg-blue-500 rounded-md flex items-center justify-center text-white font-bold">Зарегистрировать</div>
        <div className="h-[20px]"></div>
        {uploading ? <div>{regMessage == "" ? "Загрузка" : regMessage}</div> : <></>}
    </div>;
}

function login() {
    return <div className=""></div>;
}
