import { React, useState } from 'react';
import { curID, sDoc } from "./assVars"
import { auth, db } from "../index"
import { collection, updateDoc, doc, getDoc, query, where, getDocs, getCount } from 'firebase/firestore/lite';
import { FirebaseImage } from '../common/firebaseImage';

export function CheckStore(props) {
    console.log(curID);
    let [ready, changeReady] = useState(false);
    let [store, changeStore] = useState({});
    let [confirmed, changeConfirmed] = useState(false);
    let [acceptedProducts, changeAcceptedProducts] = useState(0);
    let [uploading, changeUploading] = useState(false);
    let [totalProducts, changeTotalProducts] = useState(0);
    async function getStore() {
        if (!ready) {
            props.changeHomeLoading(true);
            const q = await getDoc(doc(db, "newStores", curID));
            sDoc = q;
            changeStore(q.data());
            const p = await getDoc(doc(db, "newStoreProducts", curID));
            console.log(`data ${p.data()["products"]}`);
            changeAcceptedProducts(p.data()["products"].filter((e) => e["accepted"]).length);
            changeTotalProducts(p.data()["products"].length);
            console.log(`here are numbers ${acceptedProducts} and ${totalProducts} and ${store["reviewStatus"]}`);
            if (acceptedProducts == totalProducts && store["reviewStatus"] == 2) {
                await updateDoc(doc(db, "newStores", curID), { "reviewStatus": 3, "lastChanged": new Date() });
                const q = await getDoc(doc(db, "newStores", curID));
                changeStore(q.data());
            } else {
                console.log("condition not met");
            }
            props.changeHomeLoading(false);
            changeReady(true);

        }
    }

    async function acceptInfo() {
        props.changeHomeLoading(true);
        await updateDoc(doc(db, "newStores", curID), { "reviewStatus": 2, "lastChanged": new Date() });
        const q = await getDoc(doc(db, "newStores", curID));
        changeStore(q.data());
        props.changeHomeLoading(false);
    }

    async function addStore() {
        if (!uploading) {
            props.changeHomeLoading(true);
            changeUploading(true);
            console.log("uploading the store...");
            await updateDoc(doc(db, "newStores", curID), { "reviewStatus": 4, "reviewedDate": new Date(), "lastChanged": new Date() });
            console.log('done!');
            changeUploading(false);
            props.changePage("check");
            props.changeHomeLoading(false);
        }
    }

    async function declineStore() {
        if (!uploading) {
            props.changeHomeLoading(true);
            changeUploading(true);
            console.log('updating the document...');
            await updateDoc(doc(db, "newStores", curID), { "reviewStatus": 5, "lastChanged": new Date() });
            console.log('done!');
            changeUploading(false);
            props.changeHomeLoading(false);
            props.changePage("check");
        }
    }



    getStore();
    const time = ready ? store["workHours"][0]["hours"] : { "end": "", "start": "" };
    return <div className="p-[20px] flex flex-col">
        <div className="font-bold text-[20px]">1. Информация о магазине</div>
        <div className="h-[20px]"></div>
        <div className="h-[150px] w-[100%] flex items-center rounded-lg bg-gray-300 overflow-hidden">
            <FirebaseImage reference={store["image"]}></FirebaseImage>
        </div>
        <div className="h-[10px]"></div>
        <TextD dText="Имя" mainText={store["name"]} />
        <div className="h-[10px]"></div>
        <TextD dText="Тип" mainText="Продуктовый магазин" />
        <div className="h-[10px]"></div>
        <TextD dText="Адрес" mainText={store["address"]} />
        <div className="h-[10px]"></div>
        <TextD dText="Время работы" mainText={time["start"] + " - " + time["end"]} />
        <div className="h-[10px]"></div>
        <TextD dText="Номер телефона" mainText={store["phone"]} />
        <div className="h-[20px]"></div>
        {store["reviewStatus"] < 2 ? <div className="flex flex-row justify-end">
            <div onClick={() => { props.changePage("editStore"); }} className="h-[35px] w-[100px] rounded-md bg-gray-300 flex items-center justify-center">Изменить</div>
            <div className="w-[20px]"></div>
            <div onClick={() => { acceptInfo(); }} className="h-[35px] w-[100px] rounded-md bg-gray-300 flex items-center justify-center">Все верно</div>
        </div> : <div className="flex flex-col items-center">
            <div>Информация подтверждена!</div>
            <div className="w-[20px]"></div>
            <div onClick={() => { props.changePage("editStore"); }} className="text-[16px] text-red-600">Изменить</div>
        </div>}
        <div className="h-[20px]"></div>
        <div className="font-bold text-[20px]">2. Продукты</div>
        <div className="h-[20px]"></div>
        {store["reviewStatus"] < 3 ? <div className={`opacity-${(store["reviewStatus"] >= 1 ? "100" : "30")} flex flex-col`}>
            {<div onClick={() => { if (store["reviewStatus"] >= 2) { props.changePage("checkProducts"); } }} className="h-[50px] w-[100%] rounded-lg bg-purple-500 text-white flex items-center justify-center">Проверить продукты</div>}
            <div className="h-[20px]"></div>
            <div className="self-center">{acceptedProducts + " / " + totalProducts + " Продуктов"}</div>
        </div> : <div className="flex flex-col">
            <div className="self-center text-[18px]">Продукты добавлены</div>
            <div onClick={() => { props.changePage("checkProducts"); }} className="text-red-600 text-[16px] self-center">Изменить</div>
        </div>}
        <div className="h-[60px]"></div>
        <div className={`opacity-${(store["reviewStatus"] >= 3 ? "100" : "30")}`}>
            <div className="flex flex-row">
                <input value={confirmed} onChange={(val) => {
                    if (store["reviewStatus"] >= 3) { changeConfirmed(!confirmed); } else { }
                }} type="checkbox" className="h-[40px] relative w-[40px] bg-gray-300"></input>
                <div className="w-[20px]"></div>
                <div className="text-[18px]">Я подтверждаю что информация проверена / исправлена и магазин готов принимать заказы </div>
            </div>
            <div className="h-[20px]"></div>
            <div onClick={() => {
                if (store["reviewStatus"] == 3 && confirmed) {
                    var answer = window.confirm("Вы точно готовы добавить магазин?");
                    if (answer) {
                        addStore();

                    }
                }
            }} className="h-[50px] w-[100%] rounded-lg bg-green-800 flex items-center justify-center text-white">Добавить магазин</div>
            <div className="h-[20px]"></div>
        </div>
        <div className="text-[16px] self-center">Или</div>

        <div className="h-[20px]"></div>
        <div onClick={() => {
            var answer = window.confirm("Вы точно хотите отклонить магазин?");
            if (answer) {
                declineStore();
            }
        }} className="h-[50px] w-[100%] rounded-lg bg-red-700 flex items-center justify-center text-white">Отклонить магазин</div>
    </div>;
}


function TextD(props) {
    return <div className="flex flex-col items-start">
        <div className="text-[16px] text-black/[0.6]">{props.dText}</div>
        <div className="text-[20px]">{props.mainText}</div>
    </div>;
}