import { React, Component, useState } from 'react';
import { AssistantLanding } from './landing';
import { CheckPage } from './check';
import { CheckStore } from './checkStore';
import { CheckProducts } from './checkProducts';
import { NewStore } from './newStore';
import { EditStore } from './editStore';
import { MessagePage } from './messagePage';
import { auth, db } from "../index"
import { collection, getDoc, query, where, getDocs, getCount } from 'firebase/firestore/lite';

let pages = {
    'home': "Главная",
    'check': "Проверить магазины",
    'checkStore': "Магазин",
    'checkProducts': "Продукты",
    'editStore': "Редактирование",
    'newStore': "Новый магазин",
    'messagePage': "Сообщения",
};




export function AssistantHome(props) {
    const [page, changePage] = useState("home");
    const [programText, changeText] = useState("");
    const [loading, showLoading] = useState(false);
    return <div className="flex relative h-[100%] w-[100%] flex-col overflow-hidden">
        {<AppBar changeText={() => { changeText(""); }} programText={programText} changePage={(page) => { changePage(page) }} page={page} />}
        <div className="flex flex-col top-[60px] w-[100%] fixed h-[calc(100%-60px)] overflow-scroll">
            {page == "home" ? <AssistantLanding changeHomeLoading={(val) => { showLoading(val); }} user={props.userDoc} changePage={(val) => { changePage(val); }} /> :
                page == "check" ? <CheckPage changeHomeLoading={(val) => { showLoading(val); }} toStore={() => { changePage("checkStore"); }} /> :
                    page == "checkStore" ? <CheckStore changeHomeLoading={(val) => { showLoading(val); }} changePage={(val) => { changePage(val); }} /> :
                        page == "checkProducts" ? <CheckProducts changeHomeLoading={(val) => { showLoading(val); }} changeText={(val) => { changeText(val); }} /> :
                            page == "newStore" ? <NewStore /> :
                                page == "editStore" ? <EditStore changePage={(val)=>{changePage(val);}} changeHomeLoading={(val) => { showLoading(val); }} /> :
                                    page == "messagePage" ? <MessagePage /> : <></>}
        </div>
        {loading ? <Loading></Loading> : <></>}
    </div>

}

function AppBar(props) {
    function whichPage() {
        props.changeText("");
        let p = props.page;
        if (p == "check") {
            return "home";
        } else if (p == "checkStore") {
            return "check";
        } else if (p == "checkProducts") {
            return "checkStore";
        } else if (p == "newStore") {
            return "home";
        } else if (p == "editStore") {
            return "checkStore";
        } else if (p == "messagePage") {
            return "home";
        }
    }

    return <div onClick={() => { props.changePage(whichPage()); }} className="h-[60px] fixed top-0 left-0 w-[100%] bg-gray-400 pl-[20px] pr-[20px] flex flex-row items-center">
        {props.page != "home" ? <>
            <div className="font-bold text-[24px]">{"<"}</div>
            <div className="w-[20px]"></div>
        </> : <></>}
        <div className="font-bold">{pages[props.page]}</div>
        <div className="w-[20px]"></div>
        <div className="text-[16px]">{props.programText}</div>
    </div>;
}

function Loading() {
    return <div className="bg-black/[0.5] fixed top-0 left-0 h-[100%] w-[100%] flex items-center justify-center p-[20px]">
        <div className="h-[200px] w-[100%] rounded-lg bg-white flex flex-col justify-center items-center">
            <div className="text-[20px]">Загрузка...</div>
            <div className="h-[20px]"></div>
            <img className="h-[50px] w-[50px]" src="https://i.gifer.com/ZKZg.gif"></img>
        </div>
    </div>;
}