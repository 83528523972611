import { React, useState } from "react";

export function NewStore() {

    let [info, changeInfo] = useState({
        "name": "",
        "businessType": "",
        "address": "",
        "workHours": "",
        "phone": "",
    });

    function updateVal(field, val) {
        let clone = structuredClone(info);
        clone[field] = val;
        changeInfo(clone);
    }


    return <div className="h-[100%] w-[100%] flex items-center justify-center p-[20px] text-center">Добавьте новый магазин через приложение Tayau Business и подтвердите его в "Проверить магазины"</div>
        // <div className="flex flex-col p-[20px]">
        //     <div className="font-bold text-[20px]">1. Информация о магазине</div>
        //     <Spacer />
        //     <div className="self-start">Фото магазина</div>
        //     <div className="h-[150px] w-[100%] bg-gray-300 rounded-lg flex items-center justify-center text-[30px]">+</div>
        //     <Spacer />
        //     <PrettyField i={info["name"]} onChange={(val) => { updateVal("name", val); }} name="Имя"></PrettyField>
        //     <Spacer />
        //     <PrettyField i={info["businessType"]} onChange={(val) => { updateVal("businessType", val); }} readOnly={true} name="Тип"></PrettyField>
        //     <Spacer />
        //     <PrettyField i={info["address"]} onChange={(val) => { updateVal("address", val); }} name="Адрес"></PrettyField>
        //     <Spacer />
        //     <div className="text-[16px]">Время работы</div>
        //     <TimePicker />
        //     <PrettyField i={info["phone"]} onChange={(val) => { updateVal("phone", val); }} name="Номер телефона"></PrettyField>
        //     <Spacer />
        //     <div className="font-bold text-[20px]">2. Продукты</div>
        //     <Spacer />
        //     <div className="h-[50px] w-[100%] rounded-lg bg-purple-600 text-white flex items-center justify-center">Добавить продукты</div>
        //     <Spacer />
        //     <Spacer />
        //     <Spacer />
        //     <div className="flex flex-row">
        //         <input type="checkbox" className="h-[40px] relative w-[40px] bg-gray-300"></input>
        //         <div className="w-[20px]"></div>
        //         <div className="text-[18px]">Я подтверждаю что магазин готов принимать заказы.</div>
        //     </div>
        //     <Spacer />
        //     <div className="flex flex-row"></div>
        // </div>
        ;
}

function PrettyField(props) {
    let [value, setValue] = useState(props.i);
    return <div className="flex flex-col items-start">
        <div className="text-[16px]">{props.name}</div>
        <input value={value} onChange={(val) => { setValue(val.currentTarget.value); props.onChange(val.currentTarget.value); }} readOnly={props.readOnly != null ? props.readOnly : false} className="h-[40px] pl-[20px] w-[100%] bg-gray-300 rounded-lg"></input>
    </div>;
}

function Spacer() {
    return <div className="h-[20px]"></div>;
}

function TimePicker(props) {
    return <div className="flex flex-row mt-[10px] mb-[20px] items-center">
        <div className="text-[16px]">Начало</div>
        <div className="w-[10px]"></div>
        <div className="h-[40px] w-[60px] bg-gray-300 rounded-lg flex items-center justify-center">8:00</div>
        <div className="w-[10px]"></div>
        <div className="text-[16px]">Конец</div>
        <div className="w-[10px]"></div>
        <div className="h-[40px] w-[60px] bg-gray-300 rounded-lg flex items-center justify-center">17:00</div>
    </div>;
}