import { React, useState } from "react";
import { Home } from "./home";
import { Auth } from "./auth/authWidget";
import { app, auth, db } from "./index";
import { getAuth } from "firebase/auth";


export function RootWidget() {
    const [ready, changeReady] = useState(false);
    const [user, changeUser] = useState(null);
    async function getUser() {
        if (!ready) {
            let user = await auth.currentUser;
            if (user != null) {
                changeUser(user);
            } else {
                changeUser(null);
            }
            changeReady(true);
        }
    }
    getUser();
    if (ready) {
        if (user == null) {
            return <Auth user={(val) => { changeUser(val); }}></Auth>;
        } else {
            return <Home logout={() => {
                auth.signOut();
                changeUser(null);
            }}></Home>;
        }
    } else {
        return <div>Загрузка</div>;
    }
}
