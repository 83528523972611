import { React, useState } from 'react';
import { auth, db } from "../index"
import { collection, getDoc, query, where, getDocs, getCount } from 'firebase/firestore/lite';
import { FirebaseImage } from '../common/firebaseImage';
import { curID } from "./assVars"

export function CheckPage(props) {

    let [ready, changeReady] = useState(false);
    let [stateStores, changeStores] = useState([]);

    async function getStores() {
        if (!ready) {
            props.changeHomeLoading(true);
            const q = await getDocs(query(collection(db, "newStores",), where("reviewStatus", "<=", 3), where("reviewStatus", ">=", 1)));
            console.log(`lets do some dirt ${q.docs.length}`);
            let stores = [];
            for (let i = 0; i < q.docs.length; i++) {
                stores.push(<StoreWidget doc={q.docs[i]} onClick={() => { curID = q.docs[i].id; props.toStore(); }} />);
            }
            changeStores(stores);
            console.log(`this is the stores length ${stores.length}`);
            changeReady(true);
            props.changeHomeLoading(false);
        }
    }

    getStores();

    return ready ? (stateStores.length > 0 ? <div className="p-[20px] flex flex-col">
        {stateStores}
    </div> : <div className="h-[100%] w-[100%] flex items-center justify-center">
        <div className="text-[20px]">Пока нет новых магазинов</div>
    </div>) : <></>;
}

function StoreWidget(props) {
    const doc = props.doc;
    const data = doc.data();
    return <div className="w-[100%] mb-[20px] shadow-md rounded-lg">
        <div className="h-[150px] w-[100%] rounded-lg bg-gray-200 overflow-hidden flex items-center">
            <FirebaseImage fit="object-cover" reference={data["image"]}></FirebaseImage>
        </div>
        <div className="p-[20px] w-[100%] flex flex-col">
            <div className="font-bold">{data["name"]}</div>
            <div className="">{data["address"]}</div>
            <div onClick={() => { props.onClick(); }} className="self-end h-[35px] w-[100px] flex items-center justify-center bg-purple-400 rounded-md text-white">Проверить</div>
        </div>

    </div>;
}