import { getDoc, doc, updateDoc } from 'firebase/firestore/lite';
import { curID, sug } from "./assVars";
import { auth, db } from "../index"
import { React, useState } from 'react';
import { FirebaseImage } from '../common/firebaseImage';

let curP = {
    "id": "",
    "price": "",
    "active": true,
    "accepted": false,
};


export function CheckProducts(props) {
    let [showEdit, changeEdit] = useState(false);
    let [ready, changeReady] = useState(false);
    let [products, changeProducts] = useState([]);

    async function updateProducts() {
        await updateDoc(doc(db, "newStoreProducts", curID), { "products": products });
        console.log('updated the products');
    }

    async function getProducts() {
        if (!ready) {
            props.changeHomeLoading(true);
            const q = await getDoc(doc(db, "newStoreProducts", curID));
            if (sug == null) {
                let s = await getDoc(doc(db, "suggestedProducts", "grocery"));
                sug = s.data()["products"];
            }
            let p = q.data()["products"];
            props.changeText(p.filter((e) => e["accepted"]).length + " / " + p.length + " Принято")
            console.log(q.data());
            changeProducts(q.data()["products"]);
            changeReady(true);
            props.changeHomeLoading(false);
        }
    }
    if (curP["id"] == "" && showEdit) { changeEdit(false); }

    getProducts();

    let dispProducts = [];
    for (let i = 0; i < products.length; i++) {
        dispProducts.push(<Product product={products[i]} changeProduct={(val) => {
            let i = products.findIndex((e) => e["id"] == val["id"]);
            let pClone = structuredClone(products);
            pClone[i] = val;
            changeProducts(pClone);
            props.changeText(products.filter((e) => e["accepted"]).length + " / " + products.length + " Принято")
            updateProducts();
        }} showEdit={() => { changeEdit(true); }} />);
    }

    return <div className="absolute top-0 left-0 h-[100%] w-[100%]">
        <div className="flex flex-col relative overflow-scroll">
            {dispProducts}
        </div>
        {showEdit ? <EditWidget sendProduct={(val) => {
            let i = products.findIndex((e) => e["id"] == val["id"]);
            let pClone = structuredClone(products);
            pClone[i] = val;
            changeProducts(pClone);
            props.changeText(products.filter((e) => e["accepted"]).length + " / " + products.length + " Принято")
            updateProducts();
        }} closeEdit={() => { changeEdit(false); }} /> : <></>}
    </div>;
}

function Product(props) {
    let p = sug[sug.findIndex((e) => e["id"] == props.product["id"])];
    let sp = props.product;
    console.log(sp);
    return <div className={`h-[100px] w-[100%] bg-gray-${(sp["active"] ? "100" : "300")} mb-[5px] flex-row flex items-center`}>
        <div className="w-[10px]"></div>
        <div className="h-[90px] w-[90px] float-left rounded-lgoverflow-hidden flex items-center justify-center">
            <FirebaseImage reference={p["thumbnail"]}></FirebaseImage>
        </div>
        <div className="w-[20px]"></div>
        <div className="flex w-[calc(100%-220px)] flex-col float-none items-start justify-around">
            <div className="font-bold line-clamp-2">{p["name"]}</div>
            <div className="flex w-[100%] flex-row justify-between">
                <div className="flex flex-row items-start">
                    <div className="flex flex-col items-start">
                        <div className="text-[14px] text-black/[0.6]">Размер</div>
                        <div className="text-[14px] text-black">{p["volume"]["amount"] + " " + p["volume"]["type"]}</div>
                    </div>
                    <div className="w-[10px]"></div>
                    <div className="flex flex-col items-start">
                        <div className="text-[14px] text-black/[0.6]">Цена</div>
                        <div className="text-[14px] text-black">{sp["price"] + " Т"}</div>
                    </div>
                </div>
            </div>
        </div>
        {(sp["accepted"] != null ? !sp["accepted"] : true) ? <div className="flex flex-row">
            <div onClick={() => {
                curP = {
                    "id": p["id"],
                    "shelf": p["shelf"],
                    "price": sp["price"],
                    "active": sp["active"],
                    "accepted": sp["accepted"] != null ? false : sp["accepted"],
                }; props.showEdit();
            }} className="h-[35px] w-[35px] rounded-[35px] bg-purple-300 flex items-center justify-center">✏️</div>
            <div className="w-[10px]"></div>
            <div onClick={() => {
                props.changeProduct(
                    {
                        "id": p["id"],
                        "shelf": p["shelf"],
                        "price": sp["price"],
                        "active": sp["active"],
                        "accepted": true,
                    }
                );
            }} className="h-[35px] w-[35px] rounded-[35px] bg-green-300 flex items-center justify-center">✅</div>
        </div> : <div className="flex flex-col w-[80px] items-center">
            <div>Принято</div>
            <div className="h-[5px]"></div>
            <div onClick={() => {
                curP = {
                    "id": p["id"],
                    "shelf": p["shelf"],
                    "price": sp["price"],
                    "active": sp["active"],
                    "accepted": sp["accepted"],
                }; props.showEdit();
            }} className="text-purple-500">Изменить</div>
        </div>
        }
        <div className="w-[20px]"></div>
    </div >;
}

function EditWidget(props) {
    let p = sug[sug.findIndex((e) => e["id"] == curP["id"])];
    let [product, changeProduct] = useState(curP);
    async function updateProduct() {
        props.sendProduct({
            "price": product["price"],
            "active": product["active"],
            "shelf": p["shelf"],
            "accepted": product["accepted"],
            "id": product["id"],
        });
        props.closeEdit();
    }
    if (p != undefined) {
        return <div className="fixed top-0 left-0 h-[100%] w-[100%] bg-black/[0.5]">
            <div className="absolute flex w-[100%]  flex-col bottom-0 left-0 h-[450px] bg-white rounded-tr-lg rounded-tl-lg p-[20px]">
                <div className="flex flex-row justify-between">
                    <a className="text-[20px] line-clamp-2" >{p["name"]}</a>
                    <a onClick={() => { props.closeEdit(); }} className="font-bold">X</a>
                </div>
                <div className="h-[20px]"></div>
                <div className="flex flex-row items-center">
                    <div className="h-[90px] w-[90px] rounded-lg bg-gray-300 overflow-hidden flex items-center justify-center">
                        <FirebaseImage reference={p["thumbnail"]}></FirebaseImage>
                    </div>
                    <div className="w-[20px]"></div>
                    <div className="flex flex-col items-start">
                        <div className="text-[14px] text-black/[0.6]">Размер</div>
                        <div className="text-[16px]">{p["volume"]["amount"] + " " + p["volume"]["type"]}</div>
                    </div>
                    <div className="w-[20px]"></div>
                    <div className="flex flex-col items-start">
                        <div className="text-[14px] text-black/[0.6]">Штрихкод</div>
                        <div className="text-[16px]">{p["barcode"]}</div>
                    </div>
                </div>
                <div className="h-[20px]"></div>
                <div className="flex flex-row items-center">
                    <div className="text-[16px]">Цена</div>
                    <div className="w-[20px]"></div>
                    <input type="number" onChange={(val) => {
                        let c = structuredClone(product);
                        if (val.currentTarget.value.length != 0) {
                            c["price"] = parseFloat(val.currentTarget.value);
                        } else {
                            c["price"] = 0;
                        }
                        changeProduct(c);
                    }} value={product["price"].toString()} placeholder='Цена' className="w-[200px] h-[40px] pl-[20px] bg-gray-300 rounded-md"></input>
                    <div className="w-[10px]"></div>
                    <div >Т</div>
                </div>
                <div className="h-[20px]"></div>
                <div className="flex flex-row items-center">
                    <div className="text-[16px]">Есть в магазине</div>
                    <div className="w-[20px]"></div>
                    <div onClick={() => { let c = structuredClone(product); c["active"] = false; changeProduct(c); }} className={`w-[100px] h-[40px] rounded-lg ${product["active"] ? "bg-gray-300" : "bg-purple-300"} flex items-center justify-center`}>Нет</div>
                    <div className="w-[10px]"></div>
                    <div onClick={() => { let c = structuredClone(product); c["active"] = true; changeProduct(c); }} className={`w-[100px] h-[40px] rounded-lg ${!product["active"] ? " bg-gray-300" : "bg-purple-300"} flex items-center justify-center`}>Да</div>
                </div>
                <div className="h-[20px]"></div>
                <div onClick={() => { updateProduct(); }} className="w-[100%] h-[50px] rounded-lg bg-green-800 flex items-center justify-center text-white">Сохранить</div>
            </div>;
        </div >
    } else {
        return <></>;
    }
}

