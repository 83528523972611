import { React, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RootWidget } from './rootWidget';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import { getStorage } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyA5YVRhYXTgLYBEUaK05xCsGdnOnz9pZVY",
  authDomain: "tayau-7ff18.firebaseapp.com",
  projectId: "tayau-7ff18",
  storageBucket: "tayau-7ff18.appspot.com",
  messagingSenderId: "755775705203",
  appId: "1:755775705203:web:f48292728d820e513e1a06",
  measurementId: "G-WNW3GB1TZP"
};


export let app;
export let db;
export let auth;
export let storage;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Initer />
);

function Initer() {
  const [ready, changeReady] = useState(false);
  function onAuthStateChanged(user){
    if (!ready) {
      changeReady(true);
    }
  }

  async function init() {
    app = await initializeApp(firebaseConfig);
    db = await getFirestore(app);
    auth = await getAuth(app);
    storage = getStorage();
    auth.onAuthStateChanged(onAuthStateChanged);
  }
  init();
  return ready ? <RootWidget /> : <div>loading</div>;
}