import { React, useState } from "react";
import { sDoc, curID } from "./assVars"
import { auth, db } from "../index"
import { collection, doc, getDoc, query, where, getDocs, getCount, updateDoc } from 'firebase/firestore/lite';

export function EditStore(props) {
    let data = sDoc.data();
    let [uploading, changeUploading] = useState(false);
    let [info, changeInfo] = useState({
        "name": data["name"],
        "businessType": data["businessType"],
        "address": data["address"],
        "workHours": data["workHours"],
        "phone": data["phone"],
    });

    function updateVal(field, val) {
        let clone = structuredClone(info);
        clone[field] = val;
        changeInfo(clone);
    }

    async function sendChange() {
        if (!uploading) {
            props.changeHomeLoading(true);
            changeUploading(true);
            console.log('starting to upload');
            await updateDoc(doc(db, "newStores", curID), {
                "name": info["name"],
                "businessType": info["businessType"],
                "address": info["address"],
                "workHours": info["workHours"],
                "phone": info["phone"],
            });
            props.changeHomeLoading(true);
            props.changePage("checkStore");
            changeUploading(false);
        }
    }

    return <div className="flex flex-col p-[20px]">
        <PrettyField i={info["name"]} onChange={(val) => { updateVal("name", val); }} name="Имя"></PrettyField>
        <Spacer />
        <PrettyField i={info["businessType"]} onChange={(val) => { updateVal("businessType", val); }} readOnly={true} name="Тип"></PrettyField>
        <Spacer />
        <PrettyField i={info["address"]} onChange={(val) => { updateVal("address", val); }} name="Адрес"></PrettyField>
        <Spacer />
        <div className="text-[16px]">Время работы</div>
        <TimePicker />
        <PrettyField i={info["phone"]} onChange={(val) => { updateVal("phone", val); }} name="Номер телефона"></PrettyField>
        <Spacer />
        <div onClick={() => { sendChange(); }} className="h-[50px] w-[100%] rounded-lg bg-purple-400 flex items-center justify-center text-white">Изменить</div>
    </div>;
}

function Spacer() {
    return <div className="h-[20px]"></div>;
}

function PrettyField(props) {
    let [value, setValue] = useState(props.i);
    return <div className="flex flex-col items-start">
        <div className="text-[16px]">{props.name}</div>
        <input value={value} onChange={(val) => { setValue(val.currentTarget.value); props.onChange(val.currentTarget.value); }} readOnly={props.readOnly != null ? props.readOnly : false} className="h-[40px] pl-[20px] w-[100%] bg-gray-300 rounded-lg"></input>
    </div>;
}

function TimePicker(props) {
    return <div className="flex flex-row mt-[10px] mb-[20px] items-center">
        <div className="text-[16px]">Начало</div>
        <div className="w-[10px]"></div>
        <div className="h-[40px] w-[60px] bg-gray-300 rounded-lg flex items-center justify-center">8:00</div>
        <div className="w-[10px]"></div>
        <div className="text-[16px]">Конец</div>
        <div className="w-[10px]"></div>
        <div className="h-[40px] w-[60px] bg-gray-300 rounded-lg flex items-center justify-center">17:00</div>
    </div>;
}