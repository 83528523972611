import React, { Component, useState } from 'react';
// import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, updateDoc, doc } from 'firebase/firestore/lite';
import axios from 'axios';
import { getAuth } from "firebase/auth";
import { RegistrationWid } from '../accManager';
import { app, auth, db } from "../index";




var randomColor = Math.floor(Math.random() * 16777215).toString(16);




export function AdminHome() {
  const [ready, changeReady] = useState(false);
  const [uploading, changeUploading] = useState(false);
  const [page, updatePage] = useState(0);
  const [editing, updateEditing] = useState({
    'businessTypes': [],
    'divisions': [],
    'shelves': [],
  });
  const [lists, updateLists] = useState({
    'businessTypes': [],
    'divisions': [],
    'shelves': [],
  });
  const [newObjs, updateNewObjs] = useState({
    'businessType': {},
    'division': {},
    'shelf': {
      'name_kz': "",
      "name_ru": "",
      "id": "",
      "div_ids": [""],
      "color": `0xFF${randomColor.toUpperCase()}`,
    },
  });
  const [deletingStore, changeDeletingStore] = useState(false);
  const [deleteMessage, updateDeleteMessage] = useState("");
  async function get() {
    if (!ready) {
      const col = collection(db, 'dynamicLists');
      const snap = await getDocs(col);
      const lists = snap.docs.map(doc => doc.data());
      const obj = lists[0];
      updateLists({
        'businessTypes': obj['businessTypes'],
        'divisions': obj['divisions'],
        'shelves': obj['shelves'],
      });
      changeReady(true);
    }
  }

  async function updateFirestore() {
    changeUploading(true);
    await updateDoc(doc(db, "dynamicLists", "dynamicLists"), {
      'businessTypes': lists['businessTypes'],
      'divisions': lists['divisions'],
      'shelves': lists['shelves'],
    });
    changeUploading(false);
  }

  function editableDivIds(index) {
    let ret = [];
    let ids = lists['shelves'][index]['div_ids'];
    for (let i = 0; i < ids.length; i++) {
      let selection = ids[i];
      let options = [];
      options.push(<option value="">Выбрать</option>);
      for (let c = 0; c < lists['divisions'].length; c++) {
        let curId = lists['divisions'][c]['id'];
        if (ids.findIndex((e) => e == curId) == -1) {
          options.push(<option value={curId}>{curId}</option>);
        }
        if (curId == selection) {
          options.push(<option value={curId}>{curId}</option>);
        }
      }
      ret.push(
        <div className="flex flex-row">
          <select onChange={(val) => {
            let clone = structuredClone(lists);
            clone['shelves'][index]['div_ids'][i] = val.target.value;
            updateLists(clone);
          }} value={selection}>{options}</select>
          <div className='w-[5px]'></div>
          {(i > 1 || ids.length > 1) ? <div onClick={() => {
            let clone = structuredClone(lists);
            clone['shelves'][index]['div_ids'].splice(i, 1);
            updateLists(clone);
          }} className="text-red-500 text-3xl font-bold">-</div> : <></>}
        </div>
      );
    }
    if (ids.length != lists['divisions'].length) {
      ret.push(<div onClick={() => {
        let clone = structuredClone(lists);
        clone['shelves'][index]['div_ids'].push("");
        updateLists(clone);
      }} className="h-[30px] w-[30px] rounded-[30px] bg-gray-300 text-xl flex items-center justify-center">+</div>);
    }
    return ret;
  }


  function renderShelves() {
    let shelves = [];
    for (let i = 0; i < lists['shelves'].length; i++) {
      let ids = lists['shelves'][i]['div_ids'];
      let rendIds = [];
      for (let b = 0; b < ids.length; b++) {
        rendIds.push(<div className="h-[30px] rounded-md bg-gray-400 flex items-center justify-center"><a className="mr-[10px] ml-[10px] text-white">{ids[b]}</a></div>);
      }
      shelves.push(<>
        {editing['shelves'].findIndex((e) => e == i) == -1 ? <div className="flex flex-col bg-gray-200 rounded-md shadow-md w-[350px] p-[20px] relative">
          <a className="text-[16px] font-bold">{lists['shelves'][i]['name_kz']}</a>
          <a className="text-[16px]">{lists['shelves'][i]['name_ru']}</a>
          <a className="text-[14px] text-black/[0.5]">{"id: " + lists['shelves'][i]['id']}</a>
          <div className="h-[10px]"></div>
          <div className="flex flex-wrap gap-[5px]">
            {rendIds}
          </div>
          <div className="h-[10px]"></div>
          <a onClick={() => {
            let clone = structuredClone(editing);
            clone['shelves'].push(i);
            updateEditing(clone);
          }} className="text-black/[0.7] text-[14px]">Изменить</a>
        </div> : <div className="flex flex-col bg-gray-200 rounded-md shadow-md w-[350px] p-[20px] relative">
          <div onClick={() => {
            let clone = structuredClone(lists);
            clone['shelves'].splice(i, 1);
            updateLists(clone);
            updateFirestore();
            let eclone = structuredClone(editing);
            let indToRemove = eclone['shelves'].findIndex((e) => e == i);
            eclone['shelves'].splice(indToRemove, 1);
            updateEditing(eclone);
          }} className="text-red-600 text-[14px]">Удалить</div>
          <a>Қазақша</a>
          <div className="h-[5px]"></div>
          <input value={lists['shelves'][i]['name_kz']} onChange={(val) => {
            let clone = structuredClone(lists);
            clone['shelves'][i]['name_kz'] = val.target.value;
            updateLists(clone);
          }}></input>
          <div className="h-[5px]"></div>
          <a>Русский</a>
          <div className="h-[5px]"></div>
          <input value={lists['shelves'][i]['name_ru']} onChange={(val) => {
            let clone = structuredClone(lists);
            clone['shelves'][i]['name_ru'] = val.target.value;
            updateLists(clone);
          }}></input>
          <div className="h-[5px]"></div>
          <a>id</a>
          <div className="h-[5px]"></div>
          <input value={lists['shelves'][i]['id']} onChange={(val) => {
            let clone = structuredClone(lists);
            clone['shelves'][i]['id'] = val.target.value;
            updateLists(clone);
          }}></input>
          <div className="h-[5px]"></div>
          <a>color</a>
          <div className="h-[5px]"></div>
          <input value={lists['shelves'][i]['color']} onChange={(val) => {
            let clone = structuredClone(lists);
            clone['shelves'][i]['color'] = val.target.value;
            updateLists(clone);
          }}></input>
          <div className="h-[10px]"></div>
          <div className="flex flex-wrap gap-[10px]">{editableDivIds(i)}</div>
          <div className="h-[10px]"></div>
          <button onClick={() => {
            let obj = lists['shelves'][i];
            if (obj['name_kz'] != "" && obj["name_ru"] != ""
              && obj["id"] != "" && obj["color"].length == 10 &&
              obj["div_ids"].findIndex((e) => e == "") == -1) {
              let _clone = structuredClone(editing);
              let indToRemove = _clone['shelves'].findIndex((e) => e == i);
              _clone['shelves'].splice(indToRemove, 1);
              console.log(_clone);
              updateEditing(_clone);
              updateFirestore();
            }
          }} className="h-[40px] rounded-md bg-gray-300">Изменить</button>
        </div>}</>);
    }
    shelves.push(<div className="flex flex-col bg-gray-200 rounded-md shadow-md w-[350px] p-[20px] relative">
      <a>Қазақша</a>
      <div className="h-[5px]"></div>
      <input onChange={(val) => {
        let clone = structuredClone(newObjs);
        clone['shelf']['name_kz'] = val.target.value;
        updateNewObjs(clone);
      }}></input>
      <div className="h-[5px]"></div>
      <a>Русский</a>
      <div className="h-[5px]"></div>
      <input onChange={(val) => {
        console.log(val.target.value);
        let clone = structuredClone(newObjs);
        clone['shelf']['name_ru'] = val.target.value;
        updateNewObjs(clone);
      }}></input>
      <div className="h-[5px]"></div>
      <a>id</a>
      <div className="h-[5px]"></div>
      <input onChange={(val) => {
        console.log(val.target.value);
        let clone = structuredClone(newObjs);
        clone['shelf']['id'] = val.target.value;
        updateNewObjs(clone);
      }}></input>
      <div className="h-[5px]"></div>
      <a>color</a>
      <div className="h-[5px]"></div>
      <input value={newObjs['shelf']['color']} onChange={(val) => {
        console.log(val.target.value);
        let clone = structuredClone(newObjs);
        clone['shelf']['color'] = val.target.value;
        updateNewObjs(clone);
      }}></input>
      <div className="h-[10px]"></div>
      <div className="flex flex-wrap gap-[10px]">{divIdsPicker()}</div>
      <div className="h-[10px]"></div>
      <button onClick={() => {
        let obj = newObjs['shelf'];
        console.log(obj);
        if (obj['name_kz'] != "" && obj['name_ru'] != ""
          && obj['div_ids'].findIndex((e) => e == "") == -1 && obj['color'].length == 10 && obj["id"] != "") {
          console.log('this is happening');
          let lclone = structuredClone(lists);
          lclone['shelves'].push(obj);
          updateLists(lclone);
          updateFirestore();
          let clone = structuredClone(newObjs);
          clone['shelf'] = { "name_kz": "", "name_ru": "", "div_ids": [""], "id": "", "color": `0xFF${Math.floor(Math.random() * 16777215).toString(16).toUpperCase()}` };
          updateNewObjs(clone);
        }
      }} className="h-[40px] rounded-md bg-gray-300">Добавить</button>
    </div>);
    return shelves;
  }

  function divIdsPicker() {
    let newType = newObjs['shelf'];
    let added = newType['div_ids'];
    let ret = [];
    for (let i = 0; i < newType['div_ids'].length; i++) {
      let options = [];
      options.push(<option value="">Выбрать</option>);
      for (let c = 0; c < lists['divisions'].length; c++) {
        let e = lists['divisions'][c];
        if (added.findIndex((f) => f == e['id']) == -1) {
          options.push(<option value={e['id']}>{e['id']}</option>);
        }
        if (added[i] == e['id']) {
          options.push(<option value={e['id']}>{e['id']}</option>);
        }
      }
      if (added.length != lists['divisions'].length) {
        ret.push(
          <div className="flex flex-row">
            <select onChange={(val) => {
              console.log(val.target.value);
              let clone = structuredClone(newObjs);
              clone['shelf']['div_ids'][i] = val.target.value;
              updateNewObjs(clone);
              console.log(newObjs['shelf']);
            }} className="h-[30px] w-[100px]">{options}</select>
            {i > 0 && options.length > 1 ? <div className="flex flex-row">
              <div className="w-[10px]"></div>
              <button onClick={() => {
                let clone = structuredClone(newObjs);
                clone['shelf']['div_ids'].splice(i, 1);
                updateNewObjs(clone);
              }} className="text-3xl flex items-center justify-center text-red-700">-</button>
            </div> : <></>}
          </div>
        );
      }
    }
    ret.push(<div onClick={() => {
      let clone = structuredClone(newObjs);
      clone['shelf']['div_ids'].push("");
      updateNewObjs(clone);
    }} className="h-[30px] w-[30px] bg-white rounded-[30px] flex items-center justify-center text-xl font-bold">+</div>);
    return ret;
  }

  function renderDivisions() {
    let shelves = [];
    for (let i = 0; i < lists['divisions'].length; i++) {
      shelves.push(<div>
        <a>name_kz</a>
        <a>{lists['divisions'][i]['name_kz']}</a>
        <a>name_ru</a>
        <a>{lists['divisions'][i]['name_ru']}</a>
        <a>{"id: " + lists['divisions'][i]['id']}</a>
      </div>);
    }
    shelves.push(<div>
      <a>name_kz</a>
      <input></input>
      <a>name_ru</a>
      <input></input>
      <a>{"id"}</a>
      <button>Добавить</button>
    </div>);
    return shelves;
  }

  function renderPicker() {
    const pages = ['Полки', 'Категории', "Типы магазинов", "Удалить магазин", "Управление аккаунтами"];
    let render = [];
    for (let i = 0; i < pages.length; i++) {
      render.push(<button onClick={() => {
        updatePage(i);
      }} className={`h-[40px] pl-[10px] pr-[10px] rounded-md ${page == i ? "bg-blue-400" : "bg-gray-300"} flex items-center`}>{pages[i]}</button>);
    }
    return render;
  }

  function renderStoreTypes() {
    let shelves = [];
    for (let i = 0; i < lists['businessTypes'].length; i++) {
      shelves.push(<div>
        <a>name_kz</a>
        <a>{lists['businessTypes'][i]['name_kz']}</a>
        <a>name_ru</a>
        <a>{lists['businessTypes'][i]['name_ru']}</a>
        <a>{"id: " + lists['businessTypes'][i]['id']}</a>
      </div>);
    }
    shelves.push(<div>
      <a>name_kz</a>
      <input></input>
      <a>name_ru</a>
      <input></input>
      <a>{"id"}</a>
      <button>Добавить</button>
    </div>);
    return shelves;
  }

  async function deleteStore(val) {
    changeUploading(true);
    await axios({
      method: "post",
      url: "http://78.40.108.100:3000/delStore",
      data: {
        'id': val,
      },
    }).then((resp) => {
      updateDeleteMessage(resp.data);
    });
    changeUploading(false);
  }

  function renderDeleteStores() {
    return <>
      <h1 className="text-2xl font-bold">Удалить магазин</h1>
      <div className="h-[10px]"></div>
      <input id="delStoreInput" className="h-[40px] w-[400px] rounded-md bg-gray-300"></input>
      <div className="h-[10px]"></div>
      <button onClick={() => {
        let val = document.getElementById("delStoreInput").value;
        if (val != "") {
          deleteStore(val.toString());
        }
      }} className="h-[40px] w-[100px] bg-red-400 rounded-md">Удалить</button>
      <div className="h-[10px]"></div>
      <div>{deleteMessage}</div>
    </>;
  }

  get();
  if (ready) {
    return <div className={`flex flex-col p-[20px] ${!uploading ? "bg-white" : "bg-blue-300"}`}>
      <div className="flex flex-wrap gap-[10px] self-center">
        {renderPicker()}
      </div>
      <div className="h-[10px]"></div>
      {page == 0 ? <>
        <h1 className='text-3xl font-bold'>Полки</h1>
        <div className="h-[20px]"></div>
        <div className="flex flex-wrap gap-[20px]">
          {renderShelves()}
        </div>
        <div className="h-[60px]"></div>
      </> : <></>}
      {page == 1 ? <>
        <h1>Категории</h1>
        {renderDivisions()}
      </> : <></>}
      {page == 2 ? <>
        <h1>Типы магазинов</h1>
        {renderStoreTypes()}
      </> : <></>}
      {page == 3 ? <>
        {renderDeleteStores()}
      </> : <></>}
      {page == 4 ? <RegistrationWid /> : <></>}
    </div>;
  } else {
    return <div>loading</div>;
  }
}

