import { React, Component, useState } from 'react';
import { FirebaseImage } from '../common/firebaseImage';
import { Timestamp, collection, getDocs, query, where } from 'firebase/firestore/lite';
import { db } from '../index';

export function BusinessHome(props) {
    const [ready, changeReady] = useState(false);
    const [storeDoc, changeStoreDoc] = useState(null);
    const [productsDoc, changePDoc] = useState(null);
    
    let orders = [];
    let n = new Date();

    async function getStoreData() {
        if (storeDoc == null) {
            let q = await getDocs(query(collection(db, "stores"), where("UID", '==', props.storeID)));
            changeStoreDoc(q.docs[0]);
            let o = await getDocs(query(collection(db, "orders"), where("storeID", '==', props.storeID), where("createdAt", ">=", Timestamp.fromDate(new Date(n.getFullYear(), n.getMonth(), n.getDate())))));
            for(let i =0; i< o.docs.length; i++){
                orders.push(o.docs[i]);
            }
            let p = await getDocs(query(collection(db, "storeProducts"), where("storeID", '==', props.storeID)));
            changePDoc(p.docs[0]);
            console.log(productsDoc);
            changeReady(true);
        }
    }
    getStoreData();
    return ready ? <div className="flex"> <div className="flex flex-wrap gap-[20px] p-[20px]">
        <StoreWidget storeDoc={storeDoc}></StoreWidget>
        <OrdersToday orders={orders}></OrdersToday>
        <RevenueToday orders={orders}></RevenueToday>
        <Products productsDoc={productsDoc}></Products>
    </div> </div> : <></>;
}

function StoreWidget(props) {
    let data = props.storeDoc.data();
    console.log(data['image']);
    return <div className="w-[320px] h-[250px] flex flex-col rounded-md shadow-lg">
        <div className="h-[180px] w-[320px] rounded-md bg-gray-100"><FirebaseImage reference={data["image"]}></FirebaseImage></div>
        <div className="h-[70px] w-[100%] p-[20px] flex flex-row justify-between items-center">
            <div className="font-bold text-md">{data["name"]}</div>
        </div>
    </div>;
}

function OrdersToday(props) {
    let orders = props.orders;
    return <div className="h-[250px] w-[250px] bg-green-200 rounded-md p-[20px] flex flex-col justify-between items-start">
        <div className="text-xl font-bold">Заказов сегодня</div>
        <div className="w-[100%] flex flex-row justify-end">
            <div className="text-3xl font-bold">{orders.length}</div>
        </div>
    </div>;
}

function RevenueToday(props) {
    let orders = props.orders;
    let total = 0;
    for(let i = 0; i< orders.length; i++){
        let data = orders[i].data();
        total = total + data["total_kzt"];
    }
    return <div className="h-[250px] w-[250px] bg-red-200 rounded-md p-[20px] flex flex-col justify-between items-start">
        <div className="text-xl font-bold">Выручка сегодня</div>
        <div className="w-[100%] flex flex-row justify-end">
            <div className="text-3xl font-bold">{total + " ₸"}</div>
        </div>
    </div>;
}

function Products(props) {
    let data = props.productsDoc.data();
    return <button className="h-[250px] w-[250px] bg-purple-200 rounded-md p-[20px] flex flex-col justify-between items-start">
        <div className="text-xl font-bold">Продуктов</div>
        <div className="w-[100%] flex flex-row justify-end">
            <div className="text-3xl font-bold">{data["products"].length}</div>
        </div>
    </button>;
}